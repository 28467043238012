@use "./../../../styles/variables/colors" as color;

.Loader {
  display: flex;

  &Container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.275);
  }

  &Shap {
    width: 3rem;
    height: 3rem;
    background-color: color.$green;
    margin: 1rem;
    border-radius: 50%;
    border-bottom: 0 solid color.$green;
    animation: load_shap 1.2s ease infinite;

    &:nth-child(1) {
      animation-delay: 0.2s;
    }
    &:nth-child(2) {
      animation-delay: 0.4s;
    }
    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }
}

@keyframes load_shap {
  50% {
    transform: translateY(-50%);
    border-bottom-width: 1.5rem;
    box-shadow: 0 8rem 2rem rgba(61, 179, 132, 0.54);
  }
}