@use './variables/colors' as colors;

.container {
  display: flex;
  width: 100%;
  max-width: 100vw;
  background-color: colors.$light-gray;
  height: 100%;
  min-height: 100vh;

  > div:nth-child(2) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    width: 100%;
  }
}

.margin-10 {
  margin: 10px;
}

.GreenHeading {
  font-family: Montserrat, sans-serif;
  color: colors.$green;
  font-size: 15px;
  font-weight: normal;
  text-transform: uppercase;
}

.TextBold {
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  font-size: 15px !important;
  color: colors.$black;
}

.TextLabel {
  font-family: Montserrat, sans-serif;
  font-weight: normal;
  font-size: 15px !important;
  color: colors.$black;
}

.GreenText {
  font-family: Montserrat, sans-serif;
  font-weight: normal;
  font-size: 15px !important;
  font-weight: bold;
  color: colors.$green;
}

.RedText {
  font-family: Montserrat, sans-serif;
  color: #ff0000;
  font-size: 15px;
  font-weight: bold;
}

.ColumnContainer {
  display: flex;
  flex-direction: column;
}

.RowContainer {
  display: flex;
  flex-direction: row;
}
