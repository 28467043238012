@use '../../../../styles/variables/colors' as colors;

.Medication {
  .MainContainer {
    background-color: colors.$white;
    height: auto;
    padding: 10px 20px 0px 20px;
    margin-right: 25px;
  }

  .MedicineContainer {
    > div {
      border-top-style: solid;
      border-width: 2px;
      border-color: colors.$light-gray;
    }
    > div:nth-child(1) {
      border-top-style: none;
    }
  }

  .ColumnContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 25px;
  }

  .RowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 0;
    margin-bottom: 10px;

    div:nth-child(1) {
      margin-right: 60px;
      flex: 6;
    }
    div:nth-child(2) {
      flex: 3;
    }
    div:nth-child(3) {
      flex: 2;
    }
    div:nth-child(4) {
      flex: 2;
    }
    div:nth-child(5) {
      margin-right: 100px;
      flex: 2;
    }
  }

  .InputClass {
    border-width: 0;
    background-color: colors.$light-gray;
    height: 30px;
  }

  .DosageUnitContainer {
    font-size: 15px;
    position: relative;
    top: 15px;
  }

  .DeleteContainer {
    position: relative;
    top: 20px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 0;
    margin-bottom: 10px;

    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }

  .DeleteText {
    padding-left: 5px;
    font-size: 15px;
    color: rgb(220, 0, 0);
  }

  .AddMedicineContainer {
    padding: 15px 0 25px 0;
  }

  .AddMedicineButton {
    cursor: pointer;
    color: colors.$green;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    width: 185px;
    height: 15px;
    justify-content: space-between;
    align-items: center;
  }

  .PlusSymbol {
    position: relative;
    // bottom: 1px;
    font-size: 30px;
  }

  .ButtonContainer {
    margin-top: 45px;
  }

  .ButtonClass {
    border-radius: 125px;
  }
}
