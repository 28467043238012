.Calendar {
	&Container {
		margin: 4%;
	}

	&Event {
		padding: 0.2rem;

		&PatientName {
			margin: 0.4rem 0;
		}
	}

	&Popup {
		padding: 8%;
		background: white;

		&Name {
			color: red;
		}
	}
}
