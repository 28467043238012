@use '../../../styles/variables/colors' as colors;

.Dialog {
  &Container {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0,0,0,0.2);
  }

  &InnerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 40%;
    left: 55%;
    transform: translate(-50%,-50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
  }

  &Heading {
    color: #111;
    font-size: 14px;
    text-align: justify;
  }

  &ButtonsContainer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }

  &ConfirmButton {
    height: 34px;
    width: 120px;
    font-size: 12px;
    border-radius: 124px;
  }

  &CancelButton {
    height: 34px;
    width: 120px;
    font-size: 12px;
    border-radius: 124px;
    border-style: solid;
    border-width: 1px;
    color: colors.$green;
  }

  &NegativeButton {
    height: 34px;
    width: 120px;
    font-size: 12px;
    border-radius: 124px;
    background-color: rgb(193, 39, 45);
  }
}

