@use '../../../../styles/variables/colors' as colors;

.Record {
  font-family: Montserrat, sans-serif;
  .MainContainer {
    width: 100%;

    > div {
      margin-bottom: 20px;
    }
  }

  .ListItems {
    padding-left: 15px;
    > li {
      margin-top: 13px;
      margin-bottom: 13px;
    }
  }

  .personalDataContainer {
    background-color: colors.$white;
    padding: 5px 20px 5px 20px;

    > div:nth-child(1) {
      display: flex;
      padding: 0 0 10px 0;

      > div:nth-child(1) {
        margin-right: 60px;
      }
      > div:nth-child(2) {
        margin-right: 70px;
      }
      > div:nth-child(3) {
        margin-right: 70px;
      }
      > div:nth-child(4) {
        margin-right: 70px;
      }
      > div:nth-child(5) {
        margin-right: 70px;
      }
      > div:nth-child(6) {
        margin-right: 70px;
      }
    }
    > div:nth-child(2) {
      display: flex;
      border-top: 2px solid colors.$light-gray;
      padding: 10px 0;
      > div:nth-child(1) {
        margin-right: 60px;
      }
      > div:nth-child(2) {
        margin-right: 90px;
      }
      > div:nth-child(3) {
        margin-right: 90px;
      }
      > div:nth-child(4) {
        margin-right: 70px;
      }
      > div:nth-child(5) {
        margin-right: 65px;
      }
      > div:nth-child(6) {
        margin-right: 60px;
      }
    }
  }

  .PersonalDataTextLabel {
    font-size: 15px;
    font-weight: normal;
    margin-bottom: 5px;
  }

  .PersonalDataTextBold {
    font-size: 15px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 15px;
  }

  .RemarkTextContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: auto;
    background-color: colors.$white;
    padding: 10px 20px;
    > p:nth-child(1) {
      flex: 1;
    }
    > p:nth-child(2) {
      flex: 10;
    }
  }

  .SicknessMedicationContainer {
    display: flex;
    flex-direction: row;

    > div:nth-child(1) {
      flex: 1;
    }
    > div:nth-child(2) {
      flex: 1;
    }
  }

  .SicknessContainer {
    min-height: 337px;
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: colors.$white;
    margin-right: 10px;
    padding: 10px 20px;

    > div:nth-child(4) {
      border-bottom: 2px solid colors.$light-gray;
      padding-bottom: 15px;
      margin-bottom: 15px;
    }
  }

  .SicknessRowContainer {
    display: flex;
    flex-direction: row;

    > p:nth-child(1) {
      flex: 1;
    }
    > p:nth-child(2) {
      flex: 1;
    }
    > div:nth-child(2) {
      flex: 1;
    }
  }

  .LifeQualityContainer {
    height: 90%;
    display: flex;
    flex-direction: column;
    background-color: colors.$white;
    padding: 10px 20px;
  }

  .AddressContainer {
    display: flex;
    flex-direction: row;
    min-height: 100px;
    height: auto;
    background-color: colors.$white;
    padding: 10px 20px;
    margin-bottom: 20px;

    > div:nth-child(1) {
      margin-right: 100px;
    }
    > div:nth-child(2) {
      flex-direction: column;
    }
  }

  .MedicationContainer {
    min-height: 304px;
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: colors.$white;
    padding: 10px 20px;
  }

  .MedicationRowContainer {
    display: flex;
    flex-direction: row;

    > p:nth-child(1) {
      flex: 2;
    }
    > p:nth-child(2) {
      flex: 1;
    }
  }

  .SecondaryDiagnosisContainer {
    display: flex;
    background-color: colors.$white;
    padding: 20px 30px 20px 20px;

    > div:nth-child(1) {
      flex: 1;
    }
    > div:nth-child(2) {
      flex: 1;
    }
    > div:nth-child(3) {
      flex: 1;
    }
  }

  .SecondaryDiagnosisRowContainer {
    display: flex;
  }

  .SecondaryDiagnosisTextLabel {
    font-size: 15px;
    font-weight: normal;
  }

  .SecondaryDiagnosisBoldText {
    font-size: 15px;
    font-weight: bold;
    margin-left: 25px;
  }

  .marginLeft25 {
    margin-left: 25px;
  }

  .ComplaintsContainer {
    height: 90%;
    display: flex;
    flex-direction: column;
    background-color: colors.$white;
    margin-right: 10px;
    padding: 10px 20px;
  }

  .ComplaintsRowContainer {
    display: flex;
    flex-direction: row;

    > p:nth-child(1) {
      flex: 3;
    }
    > p:nth-child(2) {
      flex: 1;
    }
    > div:nth-child(2) {
      flex: 1;
    }
  }

  .DocumentsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: colors.$white;
    padding: 20px 30px 20px 20px;
    > div:nth-child(1) {
      p {
        margin-bottom: 5px;
      }
      border-bottom: 2px solid colors.$light-gray;
      margin-bottom: 10px;
    }
  }

  .DocumentsRowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    > div:nth-child(1) {
      flex: 2;
    }
    > div:nth-child(2) {
      flex: 5;
    }
    > div:nth-child(3) {
      flex: 2;
    }
    > div:nth-child(4) {
      flex: 6;
    }
  }

  .DocumentsTextLabel {
    color: colors.$black;
    font-size: 15px;
  }
  .DocumentsTextBold {
    font-size: 15px;
    font-weight: bold;
  }

  .imgClass {
    height: 70px;
    width: 70px;
  }

  .showImgText {
    cursor: pointer;

    text-decoration: underline;
    font-size: 15px;
    color: colors.$green;
  }

  .FurtherInfoContainer {
    height: 250px;
    display: flex;
    flex-direction: column;
    background-color: colors.$white;
    margin-right: 10px;
    padding: 10px 20px;
  }

  .FormalContainer {
    height: 250px;
    display: flex;
    flex-direction: column;
    background-color: colors.$white;
    padding: 10px 20px;
  }

  .FreeTextContainer {
    height: auto;
    background-color: colors.$white;
    padding: 10px 20px;
    margin-bottom: 100px;
  }

  .FreeText {
    font-size: 15px;
    line-height: 23px;
  }

  .RowContainer {
    display: flex;
    flex-direction: row;

    > p:nth-child(1) {
      flex: 1;
    }
    > p:nth-child(2) {
      flex: 1;
    }
  }
}
