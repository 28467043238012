@font-face {
  font-family: Montserrat;
  src: url('./../assets/fonts/Montserrat-Thin.ttf') format('truetype');
  font-weight: 100;
}
@font-face {
  font-family: Montserrat;
  src: url('./../assets/fonts/Montserrat-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}
@font-face {
  font-family: Montserrat;
  src: url('./../assets/fonts/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: Montserrat;
  src: url('./../assets/fonts/Montserrat-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: Montserrat;
  src: url('./../assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: Montserrat;
  src: url('./../assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
}