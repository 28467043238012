@use "./../../../styles/variables/colors.scss" as colors;

.NavigationItem {
  display: flex;
  align-items: center;
  cursor: pointer;

  &Link {
    color: colors.$black;
    font-size: 1.6rem;
    margin: 0 0 0 0.75rem;

    &__active {
      color: colors.$green;
    }
  }
}