.Button {
  border: none;
  padding: 1rem 1.5rem;
  border-radius: 4px;
  font-family: Montserrat, sans-serif;
  cursor: pointer;

  &-primary {
    background-color: #3db384;
    color: white;
  }

  &-secondary {
    background-color: white;
    color: #3db384 !important;
  }
}