.PasswordForgot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  &Box {
    display: flex;
    flex-direction: column;
    width: 30rem;
    align-items: center;
    padding: 3rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
}