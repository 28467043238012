@use "../../../styles/variables/colors" as colors;

.InputField {
  border: none;
  outline: none;
  padding: 10px 12px;
  width: 100%;
  box-sizing: border-box;

  &::placeholder {
    font-family: Montserrat, sans-serif;
    font-style: italic;
    color: #b3b3b3;
  }
}